.Scorecard {
    display: none;
    border: 5px double white;
    background-color: #2C6E49;
    font-family: "Kode Mono", monospace;
    text-transform: uppercase;
    padding: 15px;
    margin: 10px;
    border-radius: 8px;
    font-size: large;
    font-weight: bold;
    width: 500px;
    min-height: 130px;
    max-width: 80vw;
}
.dif {
    display: inline-block;
    margin-right: 10px;
}
.dif input[type="radio"] {
    display: none;
}
.dif label {
    display: inline-block;
    cursor: pointer;
    padding: 5px 20px;
    background-color: #2C6E49;
    color: black;
    border: 1px solid transparent;
    transition: background-color 0.24s, color 0.24s, border-color 0.24s;
}
.dif label:nth-last-of-type(1){
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
}
.dif label:nth-of-type(1) {
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
}
  
.dif input[type="radio"]:checked + label {
    background-color: rgb(3, 91, 0);
    color: rgb(255, 235, 19);
    font-weight: bold;
    border-color: black;
  }
  
.s, .m, .t {
    display: inline-block;
    border-style: double;
    border-width: 3px;
    line-height: 25px;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
}
.s {
    background-color: rgb(101, 234, 88);
    border-color:  rgb(0, 113, 0);
    color:rgb(130, 74, 2);
    
}
.m {
    background-color: rgb(99, 177, 255);
    border-color: rgb(0, 106, 255);
    color: rgb(92, 92, 33);
}
.t {
    background-color: pink;
    border-color: rgb(255, 66, 98);
    color: black;
    
}
#leaderboardBtn span, #guideBtn span{
    background-color: transparent;
    color: white;
    font-size: 25px;
    font-weight: 700;
}
#leaderboardBtn, #guideBtn{
    padding: 0;
    margin: 10px 25px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    /* font-size: xx-large; */
}