.Tile{
    height: 80px;
    width: 80px;
    border-radius: 8px;
    box-shadow: 1px 1px 4px 1px #4a4a4a;
    border: 2px solid #717171;
    display: inline-block;
    margin: 3px;
    padding: 0px;
    overflow: hidden;
}

.Tile .TileImg{
    background-color: #1c1c1c;
    max-width: 100%;
    overflow: hidden;
}
.Tile .TileImg img{
    max-width: 100%;
    height: auto;
}
.Tile .TileCover{
    height: 84px;
    width: 84px;
    overflow: hidden;
    
    background-color: #1c1c1c;
    position: relative;
    top: -85px;
    left: 0px;
    z-index: 2;
    transition: background-color 0.4s;
    
}
div.Tile.open div.TileCover{
    background-color: transparent;
}

div.Tile.finalOpen div.TileCover{
    background-color: transparent;
}



@media screen and (max-width: 500px){
    .Tile{
      height: 50px;
      width: 50px;
      border-radius: 5px;
      box-shadow: 1px 1px 2px 1px #4a4a4a;
      border: 1.3px solid #717171;
      margin: 2px;
  }
  
  .Tile .TileCover{
      
      top: -58px;
      left: 0px;
  }
  }
  