.App {
  text-align: center;
  min-height: 100vh;
  padding: 10px;
  font-family: "Kode Mono", monospace;
}
.App h1{
  font-family: "Oswald", sans-serif;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 3em;
  text-shadow:
      0 0 7px #fff,
      0 0 92px #0fa,
      0 0 102px #0fa,
      0 0 151px #0fa;
}

.resetBtn, .startBtn{
  background-image: url('/public/assets/images/red-btn-img.jpeg');
  border: none;
  background-repeat:no-repeat;
  background-size: cover;
  width: 110px;
  height: 60px;
  cursor: pointer;
  padding: 0;
  border-radius: 30px;
  font-weight: bold;
  font-family: "Kode Mono", monospace;
  font-size: large;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(135, 135, 135);
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: black;
}
.resetBtn:hover, .startBtn:hover{
  color: yellow;
}
.difficultyParent{
  padding: 20px;
  border-radius: 50px;
  display: inline-block;
  margin: 10px auto;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  align-self: center;
  float: none;


  background: rgba( 92, 92, 92, 0.1 );
  box-shadow: 0 8px 32px 0 rgba(14, 87, 24, 0.37);
  backdrop-filter: blur( 8px );
  -webkit-backdrop-filter: blur( 8px );
  border: 1px solid rgba( 255, 255, 255, 0.18 );


}
.difficultyParent .dif{
  padding-bottom: 10px;
}
.resetBtn{
  display: none;
}
footer{
  text-align: center;
  background-color: rgb(0, 0, 0);
  color: #33d230;
  padding: 20px;
  /* border-top: 2px solid rgb(132, 132, 132); */
  border-radius: 4px;
}
footer a:visited, footer a{
  color: #ccc73f;
}

footer a:hover, footer a:active{
  color: #fff71d;
}
.App p{
  font-family: "Oswald", sans-serif;
}